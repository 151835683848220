// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
query GetDossierDetailInformation($dossierTypeSlug: String!) {
  dossierDetailInformation: dossierTypeDetailInformationByDossierTypeSlug(dossierTypeSlug: $dossierTypeSlug) {
    blocks {
      id
      name
      location
      label
    }
  }
  tour: tourByDossierTypeSlug(dossierTypeSlug: $dossierTypeSlug) {
    id
    label
    show
    settings
    steps {
      target
      header
      content
      placement
      offset
    }
  }
}
`;

export default QUERY;
